import React from "react";
import SEO from "../components/atoms/seo";
import "../styles/pages/_thank-you-new.scss"
import {
  Logo,
  Hero,
  CardA,
  CardB,
  CardC,
  im1,
  im2,
  im3,
  im4,
  stars,
  ba,
  guarantee,
  phone
} from "../images/Renuity_TYP_V2_For_Kelly"
import CountdownTimer from "../components/atoms/countdown";
const ThankYouNEWPage = () => (

  <main className="co-thank-you-page">
    <SEO title="Thank you" />

    <header style={{backgroundImage: `url(${Hero})`}}>
      <figure>
        <img src={Logo} alt="logo" />
      </figure>

      <div className="co-thank-you-page__hero">
        <div className="co-thank-you-page__hero-container">

          <div className="co-thank-you-page__counter-section">

            <div className="co-thank-you-page__counter-section-copy">
              <div className="co-thank-you-page__flex">
                <img src={phone} alt="logo" />
                <h2>We'll call you!</h2>
              </div>
              <p>Be ready for a call from one of our experts in: </p>
            </div>

            <div className="co-thank-you-page__counter-section-timer">
              <CountdownTimer />
            </div>
          </div>
          <div className="co-thank-you-page__call-section">
            <p>Or give us a call here: <a href="tel:8449502436">844-950-2436</a></p>
          </div>
          <div className="co-thank-you-page__service-area">
            <div>
              <h2>Great News!</h2>
              <p>We service your area.</p>

            </div>
          </div>
        </div>
      </div>


    </header>

    {/* formula section */}
    <section className="co-thank-you-page__section co-thank-you-page__section--lg-top">

      <div className="co-thank-you-page__section-container">
        <h3 className="title">Our Propietary Formula</h3>

        <div className="co-thank-you-page__cards-container">
          <div className="card">
            <div className="card-image" style={{backgroundImage: `url(${CardA})`}}></div>
            <span className="card-footer">
              <p>20x Stronger Than&nbsp;Epoxy</p>
            </span>
          </div>
          <div className="card">
            <div className="card-image" style={{backgroundImage: `url(${CardB})`}}></div>
            <span className="card-footer">
              <p>Available In Multiple&nbsp;Colors &&nbsp;Patterns</p>
            </span>
          </div>
          <div className="card">
            <div className="card-image" style={{backgroundImage: `url(${CardC})`}}></div>
            <span className="card-footer">
              <p>Installed In 1&nbsp;Day</p>
            </span>
          </div>
        </div>
      </div>

    </section>
    <section className="co-thank-you-page__section">
      <div className="co-thank-you-page__trust-section">
        <div className="co-thank-you-page__trust-section-logos">
            <img src={im1} />
            <img src={im3} />
            <img src={im4} />
            <img src={im2} />
        </div>
        <p>
          With thousands of homeowners served,
          Statewide Remodeling is one of the most trusted home
          remodeling companies in the nation.
        </p>
      </div>

    </section>
    <section className="co-thank-you-page__section">
      <div className="co-thank-you-page__reviews-section">

        <h3>Thousands of Happy Customers</h3>
        <h4 className="subtitle">Here’s what some of them had to say</h4>

        <div className="co-thank-you-page__cards-container reviews-section">
          <div className="review">
            <figure>
              <img src={stars} />
            </figure>

            <p>Pulling into our garage now makes our entire home look brand new.
              The team was meticulous and extremely professional. I would highly
              recommend Statewide Remodeling</p>
            <span>- Joseph S.</span>
          </div>
          <div className="review">
            <figure>
              <img src={stars} />
            </figure>

            <p>
            Statewide Remodeling did a wonderful job with my garage floor. From providing a
              variety of color options to choose from, to the detailed installation
              process, I could not be happier with the work and the finished product.
            </p>
            <span>- Gale P.
            </span>
          </div>
          <div className="review">
            <figure>
              <img src={stars} />
            </figure>

            <p>I honestly did not think it was possible, but the Statewide Remodeling team really
              transformed my entire garage in just a few hours. They did exactly what
              they said they were going to do. The team was punctual and easy to
              work with. Overall, I had a smooth and fantastic experience. Now, our
              garage looks better than new!
            </p>
            <span>- Mary V</span>
          </div>

        </div>


      </div>

    </section>

    <section className="co-thank-you-page__section">
      <div className="co-thank-you-page__compare-section">
        <div className="results-container">
          <div className="results-image" style={{backgroundImage: `url(${ba})`}}></div>
          <div className="tag-container">
            <span className="tag">BEFORE</span>
            <span className="tag">AFTER</span>
          </div>


        </div>
        <h3 className="title">Don’t Miss Out on The Benefits
          From Coating Your Garage Floor
        </h3>
        <div className="divider">
          <div className="diamond"></div>
          <hr />
        </div>

        <div className="co-thank-you-page__cards-container benefits-section">
          <div className="benefit">

            <div className="benefit-title-container">
              <span className="number">1</span>
              <h5 className="subtitle">Keeps Your Garage Brand New
              </h5>
            </div>

            <p>
              Your garage is one of the most used areas in your home so why not treat it like
              your family room? Our coating will make your garage look brand new, even years
              down the line. With multiple colors, textures, and patterns, you have endless
              options to customize to your style.
            </p>

          </div>
          <div className="benefit">

            <div className="benefit-title-container">
              <span className="number">2</span>
              <h5 className="subtitle">Easier To Clean
              </h5>
            </div>

            <p>
              Long gone are the days where you spend hours cleaning your garage floor. With
              our proprietary coating, it only takes minutes to clean.
            </p>

          </div>
          <div className="benefit">

            <div className="benefit-title-container">
              <span className="number">3</span>
              <h5 className="subtitle">It’s Safe
              </h5>
            </div>

            <p>
              Garage floors are notorious for being slippery which can cause serious accidents
              leading to serious injury. Our coating is 100% non-slip allowing you to walk around
              your garage with peace of mind.
            </p>

          </div>
        </div>
      </div>
    </section>
    <section className="co-thank-you-page__section">
      <div className="co-thank-you-page__guarantee-section">

        <h3 className="title">Lifetime Warranty Included</h3>

        <figure>
          <img src={guarantee} />
        </figure>
        <p>
          If anything happens to your coating(which is highly unlikely), no
          matter how much time has passed, we will fix it at no cost.
        </p>
        <h4 className="subtitle">NO QUESTIONS ASKED.</h4>
      </div>
    </section>
  </main>
)

export default ThankYouNEWPage;
